<template>
  <div class="page--settings">
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card
            :class="{ 'mb-6': settings.length - 1 !== pi }"
            rounded="lg"
            v-for="(setting, pi) in settings"
            :key="`${setting.title}-${pi}`"
          >
            <v-list subheader two-line>
              <v-subheader inset class="ml-0">
                <v-icon
                  class="mr-4"
                  v-if="setting.icon"
                  v-text="setting.icon"
                ></v-icon>
                <span v-text="setting.title" class="break-spaces"></span>
              </v-subheader>

              <v-list-item
                v-for="(item, ii) in setting.items"
                :key="`${item.title}-${ii}`"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon
                      class="mr-3"
                      v-if="item.icon"
                      v-text="item.icon"
                    ></v-icon>
                    <span v-text="item.title" class="break-spaces"></span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="break-spaces" v-text="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <template v-if="item.type === Boolean">
                    <v-switch
                      inset
                      v-model="item.value"
                      :loading="item.isSaving"
                      @change="saveSettingChange(item, item.value)"
                    ></v-switch>
                  </template>
                  <template v-else>
                    <v-btn
                      rounded
                      text
                      class="text-none grey--text"
                      @click="onSettingClick(setting, item)"
                    >
                      <span class="d-none d-md-flex break-spaces" v-text="getSettingValue(item)"></span>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </template>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="settingUpdateForm.setting"
      v-model="settingUpdateForm.isVisible"
      :scrollable="settingUpdateForm.setting.type === Array"
      max-width="350px"
      persistent
    >
      <v-card rounded="lg">
        <v-card-title
          :class="{
            'pb-0':
              settingUpdateForm.group.subtitle ||
              settingUpdateForm.setting.type === Array,
          }"
        >
          <v-icon
            class="mr-4"
            v-if="settingUpdateForm.group.icon"
            v-text="settingUpdateForm.group.icon"
          ></v-icon>
          <span v-text="settingUpdateForm.group.title"></span>
        </v-card-title>
        <v-card-subtitle
          class="mt-0 pb-2"
          v-if="
            settingUpdateForm.group.subtitle ||
            settingUpdateForm.setting.type === Array
          "
          v-text="
            settingUpdateForm.group.subtitle ||
            settingUpdateForm.setting.title
          "
        ></v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text
          class="py-5"
          v-if="settingUpdateForm.setting.type === String"
        >
          <v-text-field
            v-if="settingUpdateForm.setting.secured"
            v-model="settingUpdateForm.newValue"
            :label="settingUpdateForm.setting.title"
            :type="settingUpdateForm.isPasswordVisible ? 'text' : 'password'"
            counter
            :append-icon="
              settingUpdateForm.isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
            "
            @click:append="
              settingUpdateForm.isPasswordVisible =
                !settingUpdateForm.isPasswordVisible
            "
          ></v-text-field>
          <v-text-field
            v-else
            v-model="settingUpdateForm.newValue"
            :label="settingUpdateForm.setting.title"
          ></v-text-field>
        </v-card-text>

        <v-card-text
          v-if="
            settingUpdateForm.setting.type === Array &&
            settingUpdateForm.setting.options &&
            Array.isArray(settingUpdateForm.setting.options.items)
          "
          style="max-height: 300px"
        >
          <v-list>
            <v-list-item
              v-for="(option, oi) in settingUpdateForm.setting.options
                .items"
              :key="oi"
            >
              <v-list-item-title v-text="option"></v-list-item-title>
              <v-list-item-action>
                <v-switch
                  v-model="settingUpdateForm.newValue"
                  inset
                  :value="option"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="settingUpdateForm.isSaving"
            color="grey darken-1"
            text
            @click="settingUpdateForm.isVisible = false"
          >
            Desistir
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="settingUpdateForm.setting.isSaving"
            @click="
              saveSettingChange(
                settingUpdateForm.setting,
                settingUpdateForm.newValue
              )
            "
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import pageLoadingMixin from "mixins/page-loading";

export default {
  name: "Settings",
  mixins: [pageLoadingMixin],
  data: () => ({
    breadcrumbs: [
      {
        text: "Início",
        to: "/",
      },
      {
        text: "Exemplos",
        to: "/exemplos/listar",
      },
      {
        text: "Exemplo de Configurações",
        to: "/exemplos/configuracoes",
      },
    ],
    settings: [
      {
        title: "Dados do usuário",
        subtitle: undefined,
        icon: undefined,
        items: [
          {
            title: "Email",
            icon: undefined,
            subtitle: "Email usado para acessar o sistema",
            type: String,
            options: undefined,
            value: "paulo.freire@educacao.pe.gov.br",
            secured: false,
            isSaving: false,
          },
          {
            title: "Senha",
            icon: undefined,
            type: String,
            options: undefined,
            value: "paulinho é o cara",
            secured: true,
            isSaving: false,
          },
          {
            title: "Nome de usuário",
            icon: undefined,
            subtitle: undefined,
            type: String,
            options: undefined,
            value: "paulo.freire",
            secured: false,
            isSaving: false,
          },
        ],
      },
      {
        title: "Aplicativo",
        subtitle: undefined,
        icon: undefined,
        items: [
          {
            title: "Notificação",
            icon: undefined,
            subtitle: undefined,
            type: Boolean,
            options: undefined,
            value: true,
            secured: false,
            isSaving: false,
          },
          {
            title: "Tema",
            icon: undefined,
            subtitle: undefined,
            type: Array,
            options: {
              single: true,
              items: ["Claro", "Escuro"],
            },
            value: "Claro",
            secured: false,
            isSaving: false,
          },
          {
            title: "Idioma",
            icon: undefined,
            subtitle: undefined,
            type: Array,
            options: {
              single: false,
              items: ["Português", "Inglês", "Espanhol"],
            },
            value: ["Português"],
            secured: false,
            isSaving: false,
          },
        ],
      },
      {
        title: "Conexões",
        subtitle: undefined,
        icon: undefined,
        items: [
          {
            title: "Conectar com Twitter",
            icon: "mdi-twitter",
            subtitle: undefined,
            type: Boolean,
            options: undefined,
            value: false,
            secured: false,
            isSaving: false,
          },
          {
            title: "Conectar com Facebook",
            icon: "mdi-facebook",
            subtitle: undefined,
            type: Boolean,
            options: undefined,
            value: true,
            secured: false,
            isSaving: false,
          },
        ],
      },
    ],
    settingUpdateForm: {
      isVisible: false,
      group: undefined,
      setting: undefined,
      newValue: undefined,
      isPasswordVisible: false,
    },
  }),
  beforeMount() {
    this.hidePageLoading();
  },
  methods: {
    getSettingValue(setting) {
      if (!setting.value || !setting.value.length) {
        return "Não informado";
      }

      if (setting.secured) {
        return "********";
      }

      return Array.isArray(setting.value)
        ? setting.value.join(", ")
        : setting.value;
    },
    onSettingClick(group, setting) {
      if (setting.type === Boolean) {
        this.settingUpdateForm.isVisible = false;
        return;
      }

      this.settingUpdateForm.isVisible = true;
      this.settingUpdateForm.group = group;
      this.settingUpdateForm.setting = setting;
      this.settingUpdateForm.newValue = setting.value;
      this.settingUpdateForm.isPasswordVisible = false;
    },
    saveSettingChange(setting, newValue) {
      setting.isSaving = true;

      // Simulando tempo de requisição à API.
      // Deve-se remover o timeout em implementação real
      setTimeout(() => {
        setting.value = newValue;
        setting.isSaving = false;
        this.settingUpdateForm.isVisible = false;
      }, 1000);
    },
  },
};
</script>